<template>
  <div style="background: #e2e2e2">
    <div class="header-solutions">
      <span class="header-font">Quiénes somos</span>
    </div>
    <div class="sub-container">
      <div class="sub-header-font">Propósito</div>
      <div class="sub-description-font">
        <strong>
          Mejorar la educación y formación profesional de las IES,</strong
        >
        a través de la sistematización curricular y el seguimiento al logro de
        los Resultados de Aprendizaje, en el contexto de la Transformación
        Digital.
      </div>
    </div>

    <div class="container-brochure">
      <div class="container-left-right">
        <div class="borchure brochure-left">
          <div class="brochure-img">
            <div class="brochure-img-left"></div>
          </div>
          <div class="font-header mb-3">Misión</div>
          <div class="font-description mb-3">
            <strong
              >Contribuir con las Instituciones de Educación Superior</strong
            >
            en los procesos de automatización y sistematización de la
            construcción y desarrollo del currículo; así como el seguimiento al
            logro de las competencias del Perfil de Egreso y a los Resultados de
            Aprendizaje de los estudiantes.
          </div>
        </div>
      </div>
      <div class="container-left-right">
        <div class="borchure brochure-right">
          <div class="brochure-img">
            <div class="brochure-img-right"></div>
          </div>
          <div class="font-header mb-3">Visión</div>
          <div class="font-description mb-3">
            <strong>
              Ser protagonista de la Transformación Digital en los procesos de
              construcción, desarrollo y seguimiento</strong
            >
            del currículo, fomentando la innovación y el traspaso de tecnología
            en la Educación Superior.
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-5">
      <div
        style="
          color: #212121;
          text-align: center;
          font-size: 2.25rem;
          font-family: Source Sans 3;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
        "
      >
        Clientes
      </div>

      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="7000"
        controls
        indicators
        background="rgb(226, 226, 226)"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide>
          <template #img>
            <div class="d-flex justify-content-center align-items-center slide-container">
              <img
                class="img-fluid carousel-img"
                src="../../assets/new-page/Logo_Finis_Terrae.png"
                alt="Logo Finis Terrae"
              />
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <div class="d-flex justify-content-center align-items-center slide-container">
              <img
                class="img-fluid carousel-img"
                src="../../assets/new-page/Logo_Duoc.png"
                alt="Logo Duoc"
              />
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <div class="d-flex justify-content-center align-items-center slide-container">
              <img
                class="img-fluid carousel-img"
                src="../../assets/new-page/Logo_IPSS.png"
                alt="Logo IPSS"
              />
            </div>
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <div class="d-flex justify-content-center align-items-center slide-container">
              <img
                class="img-fluid carousel-img-udalba"
                src="../../assets/new-page/Logo_Udalba.png"
                alt="Logo Udalba"
              />
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- <div class="container-institution-img">
        <a href="https://uft.cl" target="_blank">
          <div class="institution-img institution-finis-tarrae-img"></div>
        </a>
        <a href="https://www.duoc.cl" target="_blank">
          <div class="institution-img institution-duoc-img"></div>
        </a>
        <a href="https://ipss.cl" target="_blank">
          <div class="institution-img institution-ipss-img"></div>
        </a>
        <a href="https://www.udalba.cl" target="_blank">
          <div class="institution-img institution-udalba-img"></div>
        </a>
      </div> -->
    </div>
    <div class="about-us-img-header"></div>
    <div class="container-brochure mt-5 mb-5">
      <div class="container-left-right">
        <div class="about-us-img mb-3"></div>
      </div>
      <div class="container-left-right align-self-center">
        <div
          class="w-75 mb-3"
          style="margin-left: auto; margin-right: auto; text-align: left"
        >
          <p>
            Key Learning SpA es una empresa chilena, con proyección hacia
            Latinoamérica,
            <strong
              >que nace para satisfacer soluciones en las Instituciones de
              Educación Superior relacionadas con los modelos formativos</strong
            >, incorporando tecnologías, procesos y mecanismos que aseguren la
            calidad y pertinencia, conforme sus propios proyectos educativos y
            las exigencias de criterios y estándares de los sistemas nacionales
            e internacionales de aseguramiento de la calidad.
          </p>
          <p>
            El equipo que conforma la empresa esta constituido por profesionales
            y académicos con vasta experiencia en la Gestión Académica en la
            Educación Superior, trabajando en el diseño, implementación y
            capacitación de modelos educativos centrados en el estudiante,
            generando mecanismos que aseguran la ejecución de innovaciones
            curriculares integradas con el trabajo pedagógico del claustro
            académico.
          </p>
        </div>
      </div>
    </div>
    <div class="sub-header-font">Equipo</div>
    <div class="team-container mt-4 mb-5">
      <div class="team-card">
        <div class="team-img-container team-img-1"></div>
        <div>
          <div class="team-font team-font-title">
            <a
              style="margin-left: auto; margin-right: 0"
              href="https://www.linkedin.com/in/jtc797/"
              target="_blank"
            >
              Jaime Torrealba Cubillos
              <b-icon icon="linkedin" aria-hidden="true"></b-icon>
            </a>
          </div>
          <div class="team-font">Gerente General</div>
        </div>
      </div>
      <div class="team-card">
        <div class="team-img-container team-img-2"></div>
        <div>
          <div class="team-font team-font-title">
            <a
              style="margin-left: auto; margin-right: 0"
              href="https://www.linkedin.com/in/ernesto-figueredo-36332b1b9/"
              target="_blank"
            >
              Ernesto Figueredo Escobar
              <b-icon icon="linkedin" aria-hidden="true"></b-icon>
            </a>
          </div>
          <div class="team-font">Director Académico</div>
        </div>
      </div>
      <div class="team-card">
        <div class="team-img-container team-img-3"></div>
        <div>
          <div class="team-font team-font-title">
            <a
              style="margin-left: auto; margin-right: 0"
              href="https://www.linkedin.com/in/andres-henriquez-perez/"
              target="_blank"
            >
              Andrés Henriquez Pérez
              <b-icon icon="linkedin" aria-hidden="true"></b-icon>
            </a>
          </div>
          <div class="team-font">Director de Desarrollo Tecnológico</div>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
    <ContactBanner></ContactBanner>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "AboutUsView",
  components: {
    ContactForm: () => import("@/components/new-page/ContactForm"),
    ContactBanner: () => import("@/components/new-page/ContactBanner"),
  },
  props: {
    // allows_crud: {
    //   type: Boolean,
    // },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.slide-container{
  min-height: 400px;
}
.carousel-img {
  width: 50%; /* Cada imagen ocupará la mitad del slide */
  max-width: 600px !important; /* Ajusta según sea necesario */
  height: 180px !important;
  padding: 0 20px; /* Espacio entre imágenes */
  margin: 20px;
}
.carousel-img-udalba {
  width: 50%; /* Cada imagen ocupará la mitad del slide */
  max-width: 500px !important; /* Ajusta según sea necesario */
  height: 250px !important;
  padding: 0 50px; /* Espacio entre imágenes */
  margin: 20px;
}
.header-solutions {
  width: 100%;
  height: 140px;
  background: #006ec8;
  border-bottom-right-radius: 40px;
  display: flex;
  align-items: center;
}
.header-font {
  margin-left: 10%;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #ffffff;
}
.sub-container {
  margin-top: 50px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.sub-header-font {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  /* or 20px */
  text-align: center;
  color: #212121;
}
.sub-description-font {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 36px;
  line-height: 120%;
  width: 50%;
  align-self: center;
  text-align: left;

  color: #212121;
}

.container-brochure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
}
.borchure {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 513px;
  background: #006ec8;
  border-radius: 5px;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 10%;
  margin-left: 10%;
  align-items: center;
}
.brochure-left {
  /* background: #006ec8; */
  background: url(../../assets/new-page/Mision-recuadro.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.brochure-right {
  /* background: #01adb7; */
  background: url(../../assets/new-page/vision-recuadro.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.brochure-img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 211px;
  margin-top: -50px;
  margin-bottom: 2em;
}
.brochure-img-left {
  width: auto;
  height: 100%;
  background: url(../../assets/new-page/Mision-imagen.svg) no-repeat center
    center;
}
.brochure-img-right {
  width: auto;
  height: 100%;
  background: url(../../assets/new-page/vision-imagen.svg) no-repeat center
    center;
}
.font-header {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;

  text-align: center;
  letter-spacing: -0.022em;
  text-transform: uppercase;

  color: #f5f5f5;
}
.font-description {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  letter-spacing: -0.022em;
  width: 50%;
  color: #f5f5f5;
}
.container-left-right {
  display: block;
  width: 50%;
}
.about-us-img-header {
  width: 100%;
  height: 343px;
  background: url(../../assets/new-page/ABOUT_US_IMG.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.about-us-img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 500px;
  background: url(../../assets/new-page/ABOUT_US_IMG_2.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.team-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.team-card {
  width: 300px;
}
.team-img-container {
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 313px;
}
.team-img-1 {
  background: url(../../assets/new-page/Jaime_Torrealba_Cubillos.jpg) no-repeat
    center center;
  /* background: url(https://media.licdn.com/dms/image/D4E03AQFfFTngWZCqEg/profile-displayphoto-shrink_200_200/0/1685760805831?e=1691625600&v=beta&t=wN9BoGA7680YLJCPiAQeEqf0QNupuwSACnUcxGrVFoM)
    no-repeat center center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.team-img-2 {
  background: url(../../assets/new-page/Ernesto_Figueredo.jpeg) no-repeat center
    center;
  /* background: url(https://media.licdn.com/dms/image/C4D03AQE67HUajlqvAA/profile-displayphoto-shrink_800_800/0/1603562221090?e=2147483647&v=beta&t=LMtLB2qSWPlNkD5cuSVzns2Fr9DYEx8bP9HFj_wyS3A)
    no-repeat center center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.container-institution-img {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.institution-img {
  width: 500px;
  height: 230px;
}
.institution-finis-tarrae-img {
  background: url(../../assets/new-page/Logo_Finis_Terrae.png) no-repeat;
  background-size: contain;
  background-position: center;
}
.institution-duoc-img {
  background: url(../../assets/new-page/Logo_Duoc.png) no-repeat;
  background-size: contain;
  background-position: center;
}
.institution-ipss-img {
  background: url(../../assets/new-page/Logo_IPSS.png) no-repeat;
  background-size: contain;
  background-position: center;
}
.institution-udalba-img {
  background: url(../../assets/new-page/Logo_Udalba.png) no-repeat;
  background-size: contain;
  background-position: center;
}
.team-img-3 {
  background: url(../../assets/new-page/Andres_Henriquez_Perez.jpeg) no-repeat
    center center;
  /* background: url(https://media.licdn.com/dms/image/D4E03AQFQPZjc-opioA/profile-displayphoto-shrink_200_200/0/1684443411241?e=1691020800&v=beta&t=7QVSJ9DpA4PsqH9OkloH6N5q3Ekv0BvdsUPz5R5tqrQ)
    no-repeat center center; */
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.team-font {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: justify;
}
.team-font-title {
  color: #006ec8;
}
@media (max-width: 1000px) {
  .brochure-img {
    display: none;
  }
  .container-brochure {
    flex-direction: column;
  }
  .container-left-right {
    width: 100%;
    text-align: justify;
  }
  .borchure {
    margin: 2%;
    height: auto;
    justify-content: center;
  }
  .font-description {
    width: 80%;
  }
  .sub-description-font {
    width: 80%;
    text-align: justify;
    font-size: 28px;
  }
}
@media (max-width: 550px) {
  .institution-img {
    width: 25rem;
  }
}
@media (max-width: 450px) {
  .institution-img {
    width: 20rem;
  }
}
@media (max-width: 350px) {
  .institution-img {
    width: 15rem;
  }
}
</style>